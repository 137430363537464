<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">

            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('role-modal', {id: 0}, shown)"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.statuses.label.name') }}</b-th>
                            <b-th>{{ $t('settings.statuses.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>

                        <b-tr v-for="role in roles" :key="role.id">
                            <b-td>{{ role.role }}</b-td>
                            <b-td>
                                <b-button
                                    @click="$root.$children[0].openModal('role-modal', {id: role.id},shown)"
                                    size="sm"
                                    variant="primary"
                                    class="mr-2"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>

                                <b-button @click="deleteRole(role.id)"
                                          size="sm"
                                          variant="danger"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Settings-roles',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            roles: [],
        }
    },
    methods: {
        ...mapGetters('Roles', ['getRoles']),
        shown() {
            this.loading = false
            const rolesFetch = this.$store.dispatch('Roles/fetchRoles')

            Promise.all([rolesFetch])
                .then(() => {
                    this.roles = this.getRoles()
                })
                .finally(() => {
                    this.loading = false
                })
            this.$root.afterSettingsUpdate(true, '/settings/users', 'roles')
        },
        deleteRole(id) {
            this.$removeConfirm('Roles/deleteRole', id, this.shown)
        }
    },
    mounted() {
        this.shown()
    }
}
</script>